@import "@nutkit/tokens/dist/common/web/tokens.scss";
.logout-success__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: brightness(0.3);
  background-size: cover;
  background-position: center center;
}

.logout-success__vertical-line {
  border-left: 1px solid;
  height: 3rem;
}
