@import "@nutkit/tokens/dist/common/web/tokens.scss";
@import '~@nutkit/styles/src/base/mixins/breakpoints.scss';

.nm-login__banner {
  min-height: calc(100vh - var(--nk-header-fixed-height));
}

.nm-login-container.container-fluid {
  @include nk-tablet {
    padding-left: 0;
  }

  .nm-login-container__right {
    align-self: center;
    max-width: 550px;
    margin-top: var(--nk-space-stack-lg);
    margin-bottom: var(--nk-space-stack-lg);
  }
}

.nm-login-container__left {
  height: 100%;
  display: none;

  @include nk-tablet {
    display: block;
  }
}
